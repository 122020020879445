@charset "utf-8";

// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");


// Set your brand colors
$light-blue: #65c9ff;
$dark-blue: #25557c;
$brown: #757763;
$beige-light: #d0d1cd;

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $light-blue;
$primary-dark: $dark-blue;
$link: $dark-blue;

// Update some of Bulma's component variables
// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;

@mixin arrow() {
  border: 4px solid $dark-blue;
  margin-top: 5em;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  display: block;
  height: 2em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
  transform-origin: center;
  width: 2em;
}
.arrow-mixin {
  @include arrow();
}
// Import only what you need from Bulma
@import "../node_modules/bulma/bulma.sass";
